import store from "@/state/store";

export default [
  {
    path: "/login",
    name: "login",
    component: () => import("../views/account/login.vue"),
    meta: {
      title: "Login",
      beforeResolve(routeTo, routeFrom, next) {
        // If the user is already logged in
        if (store.getters["auth/loggedIn"]) {
          // Redirect to the home page instead
          next({ name: "default" });
        } else {
          // Continue to the login page
          next();
        }
      },
    },
  },
  {
    path: "/logout",
    name: "logout",
    meta: {
      title: "Logout",
      authRequired: true,
      beforeResolve(routeTo, routeFrom, next) {
        localStorage.clear();
        sessionStorage.clear();
        next();
      },
    },
    component: () => import("../views/account/logout"),
  },
  // {
  //   path: "/register",
  //   name: "Register",
  //   component: () => import("../views/account/register.vue"),
  //   meta: {
  //     title: "Register",
  //     beforeResolve(routeTo, routeFrom, next) {
  //       // If the user is already logged in
  //       if (store.getters["auth/loggedIn"]) {
  //         // Redirect to the home page instead
  //         next({ name: "default" });
  //       } else {
  //         // Continue to the login page
  //         next();
  //       }
  //     },
  //   },
  // },
  {
    path: "/forgot-password",
    name: "Forgot password",
    component: () => import("../views/account/forgot-password.vue"),
    meta: {
      title: "Forgot Password",
      beforeResolve(routeTo, routeFrom, next) {
        // If the user is already logged in
        if (store.getters["auth/loggedIn"]) {
          // Redirect to the home page instead
          next({ name: "default" });
        } else {
          // Continue to the login page
          next();
        }
      },
    },
  },
  {
    path: "/reset-password/:token",
    name: "Reset Password",
    component: () => import("../views/account/reset-password.vue"),
    meta: {
      title: "Reset Password",
      beforeResolve(routeTo, routeFrom, next) {
        // If the user is already logged in
        // Continue to the login page
        next();
      },
    },
  },

  // Utility Pages
  {
    path: "/blank",
    name: "Blank",
    meta: { 
      title: "Blank", 
      authRequired: true 
    },
    component: () =>
      import("../views/utility-pages/blank.vue"),
  },
  {
    path: "/error/no-permission",
    name: "ErrorNoPermission",
    meta: { 
      title: "No Permission", 
      authRequired: true 
    },
    component: () =>
      import("../views/utility-pages/no-permission.vue"),
  },

  // Dashboard
  {
    path: "/",
    name: "default",
    meta: {
      title: "Dashboard",
      authRequired: true,
    },
    // component: () => import("../views/pages/home.vue"),
    component: () => import("../views/home/dashboard/index.vue"),
  },
  {
    path: "/dashboard/ar",
    name: "ARDashboard",
    meta: {
      title: "AR Dashboard",
      authRequired: true,
    },
    // component: () => import("../views/home/dashboard/ar-dashboard.vue")
    component: () => import("../views/home/ar-dashboard/index")
  },
  {
    path: "/dashboard/ap",
    name: "APDashboard",
    meta: {
      title: "AP Dashboard",
      authRequired: true,
    },
    component: () => import("../views/home/ap-dashboard/index")
  },
  {
    path: "/dashboard/expenses",
    name: "dashboard-expenses",
    meta: {
      title: "Expenses Dashboard",
      authRequired: true,
    },
    // component: () => import("../views/pages/home.vue"),
    component: () => import("../views/home/dashboard/expenses-dashboard.vue"),
  },

  // Maintenance

  // Operating Unit
  {
    path: "/maintenance/ou/index",
    name: "OperatingUnit",
    meta: { title: "Operating Unit", authRequired: true },
    component: () => import("../views/maintenance/ou/index"),
  },

  // Account Groups
  {
    path: "/maintenance/account-groups/index",
    name: "AccountGroups",
    meta: { title: "Account Group", authRequired: true },
    component: () => import("../views/maintenance/account-groups/index"),
  },

  // Account Classifications
  {
    path: "/maintenance/account-classifications/index",
    name: "AccountClassifications",
    meta: { title: "Account Classifications", authRequired: true },
    component: () =>
      import("../views/maintenance/account-classifications/index"),
  },

  // Chart of Accounts
  {
    path: "/maintenance/chart-of-accounts/index",
    name: "ChartOfAccounts",
    meta: { title: "Chart of Account", authRequired: true },
    component: () => import("../views/maintenance/chart-of-accounts/index"),
  },
  {
    path: "/maintenance/chart-of-accounts/form_sub/:acct_code",
    name: "EditChartOfAccountSub",
    meta: { title: "Edit Chart of Account Sub", authRequired: true },
    component: () => import("../views/maintenance/chart-of-accounts/form_sub"),
  },

  // Cost Centers
  {
    path: "/maintenance/cost-centers/index",
    name: "CostCenters",
    meta: { title: "Cost Center", authRequired: true },
    component: () => import("../views/maintenance/cost-centers/index"),
  },

  // Clients
  {
    path: "/maintenance/clients/index",
    name: "Clients",
    meta: { title: "Clients", authRequired: true },
    component: () => import("../views/maintenance/clients/index"),
  },
  {
    path: "/maintenance/clients/form/:client_id",
    name: "AddOtherClientInfo",
    meta: { title: "Add Other Client Info", authRequired: true },
    component: () => import("../views/maintenance/clients/form"),
  },

  // Departments
  {
    path: "/maintenance/departments/index",
    name: "Departments",
    meta: { title: "Departments", authRequired: true },
    component: () => import("../views/maintenance/departments/index"),
  },

  // Employees
  {
    path: "/maintenance/employees/index",
    name: "Employees",
    meta: { title: "Employees", authRequired: true },
    component: () => import("../views/maintenance/employees/index"),
  },

  // Sales Officer
  {
    path: "/maintenance/sales-officer/index",
    name: "SalesOfficer",
    meta: { title: "Sales Officer", authRequired: true },
    component: () => import("../views/maintenance/sales-officer/index"),
  },

  // Doc Types
  {
    path: "/maintenance/doc-types/index",
    name: "DocTypes",
    meta: { title: "Doc Types", authRequired: true },
    component: () => import("../views/maintenance/doc-types/index"),
  },

  // Trans Types
  {
    path: "/maintenance/trans-types/index",
    name: "TransTypes",
    meta: { title: "Transaction Types", authRequired: true },
    component: () => import("../views/maintenance/trans-types/index"),
  },

  // Fiscal Calendar
  {
    path: "/maintenance/fiscal-calendar/index",
    name: "FiscalCalendar",
    meta: { title: "Fiscal Calendar", authRequired: true },
    component: () => import("../views/maintenance/fiscal-calendar/index"),
  },

  // Convertion Rates
  {
    path: "/maintenance/convertion-rates/index",
    name: "ConvertionRates",
    meta: { title: "Convertion Rates", authRequired: true },
    component: () => import("../views/maintenance/convertion-rates/index"),
  },

  // Payment Terms
  {
    path: "/maintenance/payment-terms/index",
    name: "PaymentTerms",
    meta: { title: "Payment Terms", authRequired: true },
    component: () => import("../views/maintenance/payment-terms/index"),
  },

  // Industry List
  {
    path: "/maintenance/industries/index",
    name: "Industry",
    meta: { title: "Industry", authRequired: true },
    component: () => import("../views/maintenance/industries/index"),
  },

  // Bank List
  {
    path: "/maintenance/banks/index",
    name: "Bank",
    meta: { title: "Bank", authRequired: true },
    component: () => import("../views/maintenance/banks/index"),
  },

  // Tax Codes
  {
    path: "/maintenance/tax-codes/index",
    name: "TaxCode",
    meta: { title: "Tax Codes", authRequired: true },
    component: () => import("../views/maintenance/tax-codes/index"),
  },

  // Analysis Codes
  {
    path: "/maintenance/analysis-codes/index",
    name: "AnalysisCode",
    meta: { title: "Analysis Codes", authRequired: true },
    component: () => import("../views/maintenance/analysis-codes/index"),
  },

  // Approver List
  {
    path: "/maintenance/approver-list/index",
    name: "ApproverList",
    meta: { title: "Approver List", authRequired: true },
    component: () => import("../views/maintenance/approver-list/index"),
  },
  
  // Vehicle List
  {
    path: "/maintenance/vehicles/index",
    name: "Vehicles",
    meta: { title: "Vehicles Codes", authRequired: true },
    component: () => import("../views/maintenance/vehicles/index"),
  },

  // Asset Location
  {
    path: "/assets/maintenance/location-management",
    name: "AssetLocMgmt",
    meta: { title: "Asset Location Mgmt", authRequired: true },
    component: () => import("../views/assets/maintenance/asset-location"),
  },
  // Asset Class
  {
    path: "/assets/maintenance/class-management",
    name: "AssetClassMgmt",
    meta: { title: "Asset Class Mgmt", authRequired: true },
    component: () => import("../views/assets/maintenance/asset-class"),
  },
  // Asset Groups
  {
    path: "/assets/maintenance/group-management",
    name: "AssetGrpMgmt",
    meta: { title: "Asset Group Mgmt", authRequired: true },
    component: () => import("../views/assets/maintenance/asset-group"),
  },
  // Asset Groups
  {
    path: "/assets/maintenance/account-rule-definition",
    name: "AssetAcctRulDef",
    meta: { title: "Asset Acct Rule Definition", authRequired: true },
    component: () =>
      import("../views/assets/maintenance/account-rule-definition"),
  },
  // Asset Template
  {
    path: "/assets/maintenance/template-management",
    name: "AssetTemplateMgmt",
    meta: { title: "Asset Template Mgmt", authRequired: true },
    component: () => import("../views/assets/maintenance/asset-template"),
  },
  // Asset Category Mgmt
  {
    path: "/assets/maintenance/category-management",
    name: "AssetCategoryMgmt",
    meta: { title: "Asset Category Mgmt", authRequired: true },
    component: () => import("../views/assets/maintenance/asset-category"),
  },
  // Asset Type
  {
    path: "/assets/maintenance/type-management",
    name: "AssetTypeMgmt",
    meta: { title: "Asset Type Mgmt", authRequired: true },
    component: () => import("../views/assets/maintenance/asset-type"),
  },
  // Asset Registry
  {
    path: "/assets/maintenance/registration-management",
    name: "AssetRegMgmt",
    meta: { title: "Asset Registration Mgmt", authRequired: true },
    component: () => import("../views/assets/maintenance/asset-registry"),
  },
  // Asset Budget
  {
    path: "/assets/maintenance/budget-management",
    name: "AssetBudgetMgmt",
    meta: { title: "Asset Budget Mgmt", authRequired: true },
    component: () => import("../views/assets/maintenance/asset-budget"),
  },
  // Asset Budget Report
  {
    path: "/assets/reports/asset-budget-report",
    name: "AssetBudgetReport",
    meta: { title: "Asset Budget Report", authRequired: true },
    component: () => import("../views/assets/reports/asset-budget-report"),
  },
  // Asset Proposal
  {
    path: "/assets/proposal-management",
    name: "AssetProposalMgmt",
    meta: { title: "Asset Proposal Mgmt", authRequired: true },
    component: () =>
      import("../views/assets/proposal-management/asset-proposal"),
  },
  // Asset Issuance
  {
    path: "/assets/issuance-management",
    name: "IssuanceList",
    meta: { title: "Asset Issuance Management", authRequired: true },
    component: () =>
      import("../views/assets/issuance/index"),
  },
  {
    path: "/assets/issuance-form",
    name: "IssuanceForm",
    meta: { title: "Asset Issuance Management", authRequired: true },
    component: () =>
      import("../views/assets/issuance/form"),
  },
  // PR Entry Form
  {
    path: "/purchasing-mgmt/purchase-request/entry",
    name: "PREntry",
    meta: { title: "PR Entry", authRequired: true },
    component: () =>
      import("../views/purchasing-management/purchase-request/pr-form"),
  },
  // PR Edit Form
  {
    path: "/purchasing-mgmt/purchase-request/:requestID/edit",
    name: "PREditForm",
    meta: { title: "PR Edit Form", authRequired: true },
    component: () =>
      import("../views/purchasing-management/purchase-request/pr-form"),
  },
  // PR Revision Form
  {
    path: "/purchasing-mgmt/purchase-request/:requestID/:validatorID/revise",
    name: "PRReviseForm",
    meta: { title: "PR Revision Form", authRequired: true },
    component: () =>
      import("../views/purchasing-management/purchase-request/pr-modification"),
  },
  // PR Approval Mgmt
  {
    path: "/purchasing-mgmt/pr-approval-mgmt",
    name: "PRsToApprove",
    meta: { title: "PR Approval Mgmt", authRequired: true },
    component: () =>
      import(
        "../views/purchasing-management/purchase-request/pr-approval-mgmt"
      ),
  },
  // PR Approval - Internal
  {
    path: "/purchasing-mgmt/purchase-request/approval/:requestID",
    name: "PurchaseRequestApproval",
    component: () =>
      import(
        "../views/purchasing-management/purchase-request/pr-approval-form"
      ),
    meta: { title: "PR Approval", authRequired: true },
  },
  // PR Approval - External
  {
    path: "/purchasing-mgmt/purchase-request/approval-ext/:requestID/:approverID/:prToken",
    name: "PurchaseRequestApprovalExt",
    component: () =>
      import("../views/purchasing-management/purchase-request/pr-approval-ext"),
    meta: {
      title: "PR Approval Mail",
      authRequired: false,
    },
  },
  // PR Report
  {
    path: "/purchasing-mgmt/report/pr-report/:requestID?",
    name: "PRReport",
    component: () =>
      import("../views/purchasing-management/purchase-request/pr-report"),
    meta: { title: "PR report", authRequired: true },
  },
  // PR Acknowledgement
  {
    path: "/purchasing-mgmt/pr-acknowledgement/:requestID?",
    name: "PRAcknowledgement",
    meta: { title: "PR Acknowledgement", authRequired: true },
    component: () =>
      import(
        "../views/purchasing-management/purchase-request/pr-acknowledgement"
      ),
  },
  // PR Acknowledgement Ext
  {
    path: "/purchasing-mgmt/purchase-acknowledgement/ext/:requestID/:acknowledgerID/:prTokenAck",
    name: "PurchaseRequestAcknowledgementExt",
    component: () =>
      import(
        "../views/purchasing-management/purchase-request/pr-acknowledgement-ext"
      ),
    meta: { title: "PR Acknowledgement Mail", authRequired: false },
  },
  // PR Validation
  {
    path: "/purchasing-mgmt/pr-validation/:requestID?",
    name: "PRValidation",
    meta: { title: "PR Validation", authRequired: true },
    component: () =>
      import("../views/purchasing-management/purchase-request/pr-validation"),
  },
  // PR Request Budget Adjustment
  {
    path: "/purchasing-mgmt/pr-budget-adjustment/:requestID?",
    name: "PRBudgetAdj",
    meta: { title: "PR Budget Adjustment", authRequired: true },
    component: () =>
      import(
        "../views/purchasing-management/purchase-request/pr-budget-adjustment"
      ),
  },
  // PR Request Budget Approval
  {
    path: "/purchasing-mgmt/pr-budget-approval",
    name: "PRBudgetApproval",
    meta: { title: "PR Budget Approval", authRequired: true },
    component: () =>
      import(
        "../views/purchasing-management/purchase-request/pr-budget-approval"
      ),
  },
  // Purchase Order Form
  {
    path: "/purchasing-mgmt/po-entry-form/:poID?",
    name: "POForm",
    meta: { title: "PO Form", authRequired: true },
    component: () =>
      import("../views/purchasing-management/purchase-order/po-form"),
  },
  // Purchase Order Report
  {
    path: "/purchasing-mgmt/purchase-order/report",
    name: "PurchaseOrderReport",
    meta: { title: "PO Report", authRequired: true },
    component: () =>
      import("../views/purchasing-management/purchase-order/po-report"),
  },
  // Purchase Order Validation - Internal
  {
    path: "/purchasing-mgmt/purchase-order/validation/:poID?",
    name: "PurchaseOrderValidation",
    meta: { title: "PO Validation", authRequired: true },
    component: () =>
      import("../views/purchasing-management/purchase-order/po-validation"),
  },
  // Purchase Order Validation - External
  {
    path: "/purchasing-mgmt/purchase-order/validation-ext/:poID/:validatorID/:poToken",
    name: "PurchaseOrderValidationExt",
    component: () =>
      import("../views/purchasing-management/purchase-order/po-validation-ext"),
    meta: { title: "PO Validation Mail", authRequired: false },
  },
  // Purchase Order Approval - Internal
  {
    path: "/purchasing-mgmt/purchase-order/approval/:poID?",
    name: "PurchaseOrderApproval",
    meta: { title: "PO Approval", authRequired: true },
    component: () =>
      import("../views/purchasing-management/purchase-order/po-approval"),
  },
  // Purchase Order Approval - External
  {
    path: "/purchasing-mgmt/purchase-order/approval-ext/:poID/:approverID/:poToken",
    name: "PurchaseOrderApprovalExt",
    component: () =>
      import("../views/purchasing-management/purchase-order/po-approval-ext"),
    meta: { title: "PO Approval Mail", authRequired: false },
  },
  {
    path: "/purchasing-mgmt/goods-receipt/list",
    name: "GR List",
    meta: { title: "GR Report", authRequired: true },
    component: () => import("../views/purchasing-management/goods-receipt/gr-list"),
  },
  {
    path: "/purchasing-mgmt/goods-receipt/form/:grID?",
    name: "GR Form",
    meta: { title: "GR Form", authRequired: true },
    // component: () => import("../views/goods-receipt/_id"),
    component: () => import("../views/purchasing-management/goods-receipt/gr-form"),
  },
  // Asset Capitalization
  {
    path: "/assets/capitalization/index",
    name: "ACapitalization",
    meta: { title: "CAPITAL WIP", authRequired: true },
    component: () => import("../views/assets/capitalization/index"),
  },
  // Asset Capitalization
  {
    path: "/assets/capitalization/form",
    name: "CapitalizationForm",
    meta: { title: "CAPITAL WIP", authRequired: true },
    component: () => import("../views/assets/capitalization/form"),
  },
  // Upload Asset Balances
  {
    path: "/assets/upload/asset-balances",
    name: "UploadAssetBalances",
    meta: { title: "UPLOAD ASSET BALANCES", authRequired: true },
    component: () => import("../views/assets/upload/asset-balances"),
  },
  // Asset Depreciation Category
  {
    path: "/assets/depreciation/depreciation-category",
    name: "DepreciationCategoryMgmt",
    meta: { title: "Depreciation Category MGMT", authRequired: true },
    component: () =>
      import("../views/assets/depreciation/depreciation-category"),
  },
  // // Asset Depreciation Setup
  // {
  //   path: "/assets/depreciation/depreciation-setup",
  //   name: "AssetDepreciation",
  //   meta: { title: "Asset Depreciation", authRequired: true },
  //   component: () => import("../views/assets/depreciation/asset-depreciation"),
  // },
  // Asset Depreciation Report
  {
    path: "/assets/depreciation/depreciation-report",
    name: "DepreciationReport",
    meta: { title: "Depreciation Report", authRequired: true },
    component: () => import("../views/assets/depreciation/depreciation-report"),
  },
  // Lapsing Schedule Report
  {
    path: "/assets/depreciation/lapsing-sched-report",
    name: "LapsingSchedReport",
    meta: { title: "Lapsing Schedule Report", authRequired: true },
    component: () =>
      import("../views/assets/depreciation/lapsing-sched-report"),
  },
  // Asset Depreciation
  {
    path: "/assets/depreciation/index",
    name: "DepreciationList",
    meta: { title: "Asset Depreciation", authRequired: true },
    component: () => import("../views/assets/depreciation/index"),
  },
  {
    path: "/assets/depreciation/form/:adepRef?",
    name: "DepreciationForm",
    meta: { title: "Asset Depreciation", authRequired: true },
    component: () => import("../views/assets/depreciation/form"),
  },
  // Asset Inventory Report
  {
    path: "/assets/inventory/inventory-report",
    name: "InventoryReport",
    meta: { title: "Inventory Report", authRequired: true },
    component: () => import("../views/assets/inventory/inventory-report"),
  },
  // OR Series
  {
    path: "/maintenance/or-series/index",
    name: "ORSeries",
    meta: { title: "OR Series", authRequired: true },
    component: () => import("../views/maintenance/or-series/index"),
  },
  // GA
  {
    path: "/transactions/ga/index",
    name: "GeneralAccounting",
    meta: { title: "General Accounting", authRequired: true },
    component: () => import("../views/transactions/ga/index"),
  },
  {
    path: "/transactions/general-accounting",
    name: "GeneralAccountingV2",
    meta: { title: "General Accounting V2", authRequired: true },
    component: () => import("../views/transactions/ga/details"),
  },
  {
    path: "/doc-status",
    name: "DocStatus",
    meta: { title: "Doc Status", authRequired: true },
    component: () => import("../views/doc-status/index"),
  },
  {
    path: "/document-tracing",
    name: "DocumentTracing",
    meta: { title: "Document Tracing", authRequired: true },
    component: () => import("../views/document-tracing/index"),
  },
  {
    path: "/document-ref-tracing",
    name: "DocumentRefTracing",
    meta: { title: "Document Ref Tracing", authRequired: true },
    component: () => import("../views/document-ref-tracing/index"),
  },
  {
    path: "/transactions/recurring-voucher",
    name: "RecurringVoucher",
    meta: { title: "Recurring Voucher", authRequired: true },
    component: () => import("../views/transactions/recurring-voucher/index"),
  },
  // OR / AR
  {
    path: "/transactions/or/index",
    name: "OR-AR",
    query: { trans_type: "or" },
    meta: { title: "OR & AR List", authRequired: true },
    component: () => import("../views/transactions/or/index"),
  },
  {
    path: "/transactions/or/form/:orno?",
    name: "CreateOR-AR",
    query: { trans_type: "or" },
    meta: { title: "Create OR / AR", authRequired: true },
    component: () => import("../views/transactions/or/form"),
  },

  // AR
  {
    path: "/transactions/ar/index",
    name: "AcknowledgementReceipt",
    query: { trans_type: "ar" },
    meta: { title: "Acknowledgement Receipt", authRequired: true },
    component: () => import("../views/transactions/or/index"),
  },
  {
    path: "/transactions/ar/form/:orno?",
    name: "CreateAcknowledgementReceipt",
    query: { trans_type: "ar" },
    meta: { title: "Create Acknowledgement Receipt", authRequired: true },
    component: () => import("../views/transactions/or/form"),
  },

  // Official Receipt Sundry
  // OR / AR Sundry
  {
    path: "/transactions/or-sundry/index",
    name: "OR-ARSundry",
    query: { trans_type: "or_sundry" },
    meta: { title: "OR & AR Sundry List", authRequired: true },
    component: () => import("../views/transactions/or/index"),
  },
  {
    path: "/transactions/or-sundry/form/:orno?",
    name: "CreateOR-ARSundry",
    query: { trans_type: "or_sundry" },
    meta: { title: "Create OR / AR Sundry", authRequired: true },
    component: () => import("../views/transactions/or/form"),
  },

  {
    path: "/transactions/sales-invoice/form",
    name: "Sales Invoice",
    meta: { title: "Sales Invoice", authRequired: true },
    component: () => import("../views/transactions/sales-invoice/form"),
  },

  {
    path: "/ar-management/or",
    name: "OfficialReceipt",
    query: { trans_type: "or" },
    meta: { title: "Official Receipt", authRequired: true },
    component: () => import("../views/ar-management/or/index"),
  },
  {
    path: "/ar-management/form",
    name: "CreateOfficialReceipt",
    meta: { title: "Create Official Receipt", authRequired: true },
    component: () => import("../views/ar-management/or/form"),
  },
  {
    path: "/ar-management/form/:control_number",
    name: "EditOfficialReceipt",
    meta: { title: "Edit Official Receipt", authRequired: true },
    component: () => import("../views/ar-management/or/form"),
  },

  {
    path: "/ar-management/or-sundry",
    name: "OfficialReceiptSundry",
    query: { trans_type: "or_sundry" },
    meta: { title: "Official Receipt Sundry", authRequired: true },
    component: () => import("../views/ar-management/or/index"),
  },
  {
    path: "/ar-management/form",
    name: "CreateOfficialReceiptSundry",
    query: { trans_type: "or_sundry" },
    meta: { title: "Create Official Receipt Sundry", authRequired: true },
    component: () => import("../views/ar-management/or/form"),
  },
  {
    path: "/ar-management/form/:control_number",
    name: "EditOfficialReceiptSundry",
    query: { trans_type: "or_sundry" },
    meta: { title: "Edit Official Receipt Sundry", authRequired: true },
    component: () => import("../views/ar-management/or/form"),
  },

  {
    path: "/transactions/offsettings/form",
    name: "Offsettings",
    meta: { title: "Offsettings", authRequired: true },
    component: () => import("../views/transactions/offsettings/form"),
  },

  {
    path: "/transactions/offsettings/index",
    name: "OffsettingsList",
    meta: { title: "Offsettings", authRequired: true },
    component: () => import("../views/transactions/offsettings/index"),
  },

  {
    path: "/transactions/offsettings/form/:offsetting_no",
    name: "EditOffsettings",
    meta: { title: "Edit Offsetting", authRequired: true },
    component: () => import("../views/transactions/offsettings/form"),
  },

  // Overpayment
  {
    path: "/transactions/overpayment-adjustment/form",
    name: "CreateOverpaymentAdjustment",
    meta: { title: "Create Overpayment Adjustment", authRequired: true },
    component: () =>
      import("../views/transactions/overpayment-adjustment/form"),
  },

  // Customer Balance Adjustment
  {
    path: "/transactions/cba/index",
    name: "CustomerBalanceAdjustment",
    meta: { title: "Customer Balance Adjustment", authRequired: true },
    component: () => import("../views/transactions/cba/index"),
  },
  {
    path: "/transactions/cba/form",
    name: "CreateCustomerBalanceAdjustment",
    meta: { title: "Create Customer Balance Adjustment", authRequired: true },
    component: () => import("../views/transactions/cba/form"),
  },
  {
    path: "/transactions/cba/form/:cba_no",
    name: "EditCustomerBalanceAdjustment",
    meta: { title: "Edit Customer Balance Adjustment", authRequired: true },
    component: () => import("../views/transactions/cba/form"),
  },

  // Menus
  {
    path: "/settings/menus",
    name: "Menus",
    meta: { title: "Menu", authRequired: true },
    component: () => import("../views/maintenance/menus/index"),
  },
  {
    path: "/settings/menus/form",
    name: "AddMenu",
    meta: { title: "Add Menu", authRequired: true },
    component: () => import("../views/maintenance/menus/form"),
  },
  {
    path: "/settings/menus/form/:id",
    name: "EditMenu",
    meta: { title: "Edit Menu", authRequired: true },
    component: () => import("../views/maintenance/menus/form"),
  },

  // Users
  {
    path: "/settings/users",
    name: "Users",
    meta: { title: "User", authRequired: true },
    component: () => import("../views/user-management/users/index"),
  },
  {
    path: "/settings/users/:id",
    name: "UserProfile",
    meta: { title: "User Profile", authRequired: true },
    component: () => import("../views/user-management/users/profile"),
  },
  {
    path: "/settings/users/form",
    name: "AddUser",
    meta: { title: "Add User", authRequired: true },
    component: () => import("../views/user-management/users/form"),
  },
  {
    path: "/settings/users/form/:id",
    name: "EditUser",
    meta: { title: "Edit User", authRequired: true },
    component: () => import("../views/user-management/users/form"),
  },

  {
    path: "/user/profile",
    name: "UserProfileView",
    meta: { title: "User", authRequired: true },
    component: () => import("../views/user-management/users/user-profile"),
  },

  // Roles
  {
    path: "/settings/roles",
    name: "Roles",
    meta: { title: "Role", authRequired: true },
    component: () => import("../views/user-management/roles/index"),
  },
  {
    path: "/settings/roles/:id",
    name: "ViewRole",
    meta: { title: "View Role", authRequired: true },
    component: () => import("../views/user-management/roles/info"),
  },
  {
    path: "/settings/roles/form",
    name: "AddRole",
    meta: { title: "Add Role", authRequired: true },
    component: () => import("../views/user-management/roles/form"),
  },
  {
    path: "/settings/roles/form/:id",
    name: "EditRole",
    meta: { title: "Edit Role", authRequired: true },
    component: () => import("../views/user-management/roles/form"),
  },
  // Permissions
  {
    path: "/settings/permissions",
    name: "Permissions",
    meta: { title: "Permission", authRequired: true },
    component: () => import("../views/user-management/permissions/index"),
  },
  {
    path: "/settings/permissions/form",
    name: "AddPermission",
    meta: { title: "Add Permission", authRequired: true },
    component: () => import("../views/user-management/permissions/form"),
  },
  {
    path: "/settings/permissions/form/:id",
    name: "EditPermission",
    meta: { title: "Edit Permission", authRequired: true },
    component: () => import("../views/user-management/permissions/form"),
  },
  // Modules
  {
    path: "/settings/modules",
    name: "Modules",
    meta: { title: "Modules", authRequired: true },
    component: () => import("../views/user-management/modules/index"),
  },
  // Data Upload
  {
    path: "/system-settings/data-upload",
    name: "DataUpload",
    meta: { title: "Data Upload", authRequired: true },
    component: () => import("../views/system-settings/index"),
  },
  {
    path: "/transactions/journal-entries",
    name: "JournalEntry",
    meta: { title: "Journal Entry", authRequired: true },
    component: () => import("../views/transactions/journal-entries/index"),
  },
  {
    path: "/transactions/journal-entries/form",
    name: "AddJournalEntry",
    meta: { title: "Add Journal Entry", authRequired: true },
    component: () => import("../views/transactions/journal-entries/form"),
  },
  {
    path: "/transactions/journal-entries/form/:id",
    name: "EditJournalEntry",
    meta: { title: "Edit Journal Entry", authRequired: true },
    component: () => import("../views/transactions/journal-entries/form"),
  },
  {
    path: "/transactions/journal-entries/voucher/:id",
    name: "ViewJournalVoucher",
    meta: { title: "View Journal Voucher", authRequired: true },
    component: () =>
      import("../views/transactions/journal-entries/view-voucher"),
  },
  {
    path: "/transactions/general-ledger",
    name: "GeneralLedger",
    meta: { title: "General Ledger", authRequired: true },
    component: () => import("../views/transactions/gl/index"),
  },
  {
    path: "/reports/trial-balance/index",
    name: "TrialBalanceReport",
    meta: { title: "Trial Balance Report", authRequired: true },
    component: () => import("../views/reports/trial-balance/index"),
  },
  {
    path: "/reports/income-statement/index",
    name: "IncomeStatementReport",
    meta: { title: "Income Statement Report", authRequired: true },
    component: () => import("../views/reports/income-statement/index"),
  },
  {
    path: "/reports/account-receivable/soa/index",
    name: "AcctRcvblSOA",
    meta: { title: "AR - SOA", authRequired: true },
    component: () => import("../views/reports/account-receivable/soa/index"),
  },
  {
    path: "/reports/account-receivable/soa/inquiry",
    name: "AcctRcvblSOAInquiry",
    meta: { title: "SOA Inquiry", authRequired: true },
    component: () => import("../views/reports/account-receivable/soa/inquiry"),
  },

  {
    path: "/transactions/billing/inquiry",
    name: "BillingInquiry",
    meta: { title: "Billing Inquiry", authRequired: true },
    component: () => import("../views/reports/billing/inquiry"),
  },
  {
    path: "/reports/account-receivable/soa/with-usd",
    name: "AcctRcvblSOAUSD",
    meta: { title: "AR - SOA", authRequired: true },
    component: () => import("../views/reports/account-receivable/soa/with-usd"),
  },
  {
    path: "/reports/account-receivable/soa/with-php-and-usd",
    name: "AcctRcvblSOAUSDANDPHP",
    meta: { title: "AR - SOA", authRequired: true },
    component: () =>
      import("../views/reports/account-receivable/soa/with-php-and-usd"),
  },
  {
    path: "/reports/account-receivable/soa/aging",
    name: "AcctRcvblSOAAging",
    meta: { title: "AR - SOA", authRequired: true },
    component: () => import("../views/reports/account-receivable/soa/aging"),
  },
  {
    path: "/reports/account-receivable/soa/with-running-balance",
    name: "AcctRcvblSOAFormat2",
    meta: { title: "AR - SOA", authRequired: true },
    component: () =>
      import("../views/reports/account-receivable/soa/with-running-balance"),
  },
  {
    path: "/reports/account-receivable/soa/with-charges",
    name: "AcctRcvblSOAFormat3",
    meta: { title: "AR - SOA", authRequired: true },
    component: () =>
      import("../views/reports/account-receivable/soa/with-charges"),
  },
  {
    path: "/reports/fs-reports",
    name: "FSReports",
    meta: { title: "FS Reports", authRequired: true },
    component: () => import("../views/reports/fs-reports/index"),
  },

  {
    path: "/reports/inventory-assets",
    name: "InventoryAssets",
    meta: { title: "Inventory Assets Reports", authRequired: true },
    component: () => import("../views/reports/inventory-assets/index"),
  },
  {
    path: "/reports/report-labels",
    name: "ReportLabels",
    meta: { title: "Report Label", authRequired: true },
    component: () => import("../views/reports/report-labels/index"),
  },
  {
    path: "/reports/report-templates",
    name: "ReportTemplates",
    meta: { title: "Report Template", authRequired: true },
    component: () => import("../views/reports/report-templates/index"),
  },
  {
    path: "/reports/report-templates/form",
    name: "AddReportTemplate",
    meta: { title: "Add Report Template", authRequired: true },
    component: () => import("../views/reports/report-templates/form"),
  },
  {
    path: "/reports/report-templates/form/:id",
    name: "EditReportTemplate",
    meta: { title: "Edit Report Template", authRequired: true },
    component: () => import("../views/reports/report-templates/form"),
  },
  {
    path: "/reports/report-generator",
    name: "ReportGenerator",
    meta: { title: "Report Generator", authRequired: true },
    component: () => import("../views/reports/report-generator/index"),
  },
  {
    path: "/transactions/customer-balance-transfer",
    name: "CustomerBalanceTransfer",
    meta: { title: "Balance Transfer", authRequired: true },
    component: () => import("../views/balance-transfer/customer-balance/index"),
  },
  {
    path: "/transactions/customer-balance-transfer/form",
    name: "CreateBalanceTransfer",
    meta: { title: "Create Balance Transfer", authRequired: true },
    component: () => import("../views/balance-transfer/customer-balance/form"),
  },

  //Data Checker
  {
    path: "/data-checker/report/index",
    name: "DataCheckerGL",
    meta: { title: "Data Checker - GL", authRequired: true },
    component: () => import("../views/data-checker/report/index"),
  },
  {
    path: "/data-checker/ga-gl-comparison",
    name: "DataCheckerGAGL",
    meta: { title: "Data Checker - GA and GL Comparison", authRequired: true },
    component: () => import("../views/data-checker/report/ga-gl-comparison"),
  },
  {
    path: "/data-checker/account-register",
    name: "DataCheckerAccountRegister",
    meta: { title: "Data Checker - Account Registry", authRequired: true },
    component: () => import("../views/data-checker/report/account-register"),
  },
  {
    path: "/data-checker/serial-registry",
    name: "SerialRegistry",
    meta: { title: "Data Checker - Serial Registry", authRequired: true },
    component: () => import("../views/data-checker/report/serial-registry"),
  },
  {
    path: "/transactions/ap-invoice",
    name: "APInvoice",
    meta: { title: "AP Invoice", authRequired: true },
    component: () => import("../views/transactions/ap-invoice/index"),
  },
  {
    path: "/transactions/ap-invoice/form",
    name: "AddAPInvoice",
    meta: { title: "Add AP Invoice", authRequired: true },
    component: () => import("../views/transactions/ap-invoice/form"),
  },
  {
    path: "/transactions/ap-invoice/form/:id",
    name: "EditAPInvoice",
    meta: { title: "Edit AP Invoice", authRequired: true },
    component: () => import("../views/transactions/ap-invoice/form"),
  },
  {
    path: "/transactions/ap-invoice/view",
    name: "ViewAPInvoice",
    meta: { title: "View AP Invoice", authRequired: true },
    component: () => import("../views/transactions/ap-invoice/view"),
  },
  {
    path: "/transactions/cdv",
    name: "CDV",
    meta: { title: "CDV", authRequired: true },
    component: () => import("../views/transactions/cdv/index"),
  },
  {
    path: "/transactions/cdv/form",
    name: "AddCDV",
    meta: { title: "Add CDV", authRequired: true },
    component: () => import("../views/transactions/cdv/form"),
  },
  {
    path: "/transactions/cdv/form/:id",
    name: "EditCDV",
    meta: { title: "Edit CDV", authRequired: true },
    component: () => import("../views/transactions/cdv/form"),
  },
  {
    path: "/transactions/cdv/view",
    name: "ViewCDV",
    meta: { title: "View CDV", authRequired: true },
    component: () => import("../views/transactions/cdv/view"),
  },
  // AP Management
  {
    path: "/accounts-payable/aging/index",
    name: "AcctPyblAging",
    meta: { title: "AP - Aging", authRequired: true },
    component: () => import("../views/ap-management/aging/index"),
  },
  {
    path: "/accounts-payable/aging-doc-num",
    name: "AcctPyblAgingDocNum",
    meta: { title: "AP - Aging Per Doc Num", authRequired: true },
    component: () => import("../views/ap-management/aging-doc-num/index"),
  },
  {
    path: "/accounts-payable/batch/list",
    name: "AcctPyblBatching",
    meta: { title: "AP - Batching", authRequired: true },
    component: () => import("../views/ap-management/batch/list"),
  },
  {
    path: "/accounts-payable/batch/add",
    name: "AcctPyblBatchAdd",
    meta: { title: "AP - Batching", authRequired: true },
    component: () => import("../views/ap-management/batch/add"),
  },
  {
    path: "/accounts-payable/batch/edit/:id",
    name: "AcctPyblBatchEdit",
    meta: { title: "AP - Batching", authRequired: true },
    component: () => import("../views/ap-management/batch/edit"),
  },
  {
    path: "/transactions/supplier-balance-transfer",
    name: "SupplierBalanceTransfer",
    meta: { title: "Balance Transfer", authRequired: true },
    component: () => import("../views/balance-transfer/supplier-balance/index"),
  },
  {
    path: "/transactions/supplier-balance-transfer/form",
    name: "CreateSupplierBalanceTransfer",
    meta: { title: "Create Balance Transfer", authRequired: true },
    component: () => import("../views/balance-transfer/supplier-balance/form"),
  },
  //AR DN/CN
  {
    path: "/transactions/note/debit-note",
    name: "ARCreateDebitNote",
    query: { trans_type: "ar", type: "test" },
    meta: {
      title: "Debit Note",
      authRequired: true,
      type: "DN",
      trans_type: "ar",
    },
    component: () => import("../views/transactions/note/index"),
  },
  {
    path: "/transactions/note/credit-note",
    name: "ARCreateCreditNote",
    query: { trans_type: "ar" },
    meta: {
      title: "Credit Note",
      authRequired: true,
      type: "CN",
      trans_type: "ar",
    },
    component: () => import("../views/transactions/note/index"),
  },
  {
    path: "/transactions/note/form/dn",
    name: "ARCreateDebitMemo",
    query: { trans_type: "ar" },
    meta: {
      title: "Debit Note",
      authRequired: true,
      type: "DN",
      trans_type: "ar",
    },
    component: () => import("../views/transactions/note/form"),
  },
  {
    path: "/transactions/note/form/cn",
    name: "ARCreateCreditMemo",
    query: { trans_type: "ar" },
    meta: {
      title: "Credit Note",
      authRequired: true,
      type: "CN",
      trans_type: "ar",
    },
    component: () => import("../views/transactions/note/form"),
  },
  //AP DN/CN
  {
    path: "/transactions/note/debit-note",
    name: "APCreateDebitNote",
    query: { trans_type: "ap" },
    meta: {
      title: "Debit Note",
      authRequired: true,
      type: "DN",
      trans_type: "ap",
    },
    component: () => import("../views/transactions/note/index"),
  },
  {
    path: "/transactions/note/credit-note",
    name: "APCreateCreditNote",
    query: { trans_type: "ap" },
    meta: {
      title: "Credit Note",
      authRequired: true,
      type: "CN",
      trans_type: "ap",
    },
    component: () => import("../views/transactions/note/index"),
  },
  {
    path: "/transactions/note/form/dn",
    name: "APCreateDebitMemo",
    query: { trans_type: "ap" },
    meta: {
      title: "Debit Note",
      authRequired: true,
      type: "DN",
      trans_type: "ap",
    },
    component: () => import("../views/transactions/note/form"),
  },
  {
    path: "/transactions/note/form/cn",
    name: "APCreateCreditMemo",
    query: { trans_type: "ap" },
    meta: {
      title: "Credit Note",
      authRequired: true,
      type: "CN",
      trans_type: "ap",
    },
    component: () => import("../views/transactions/note/form"),
  },
  // Journal Voucher
  {
    path: "/transactions/journal-vouchers",
    name: "JournalVoucher",
    meta: { title: "Journal Voucher", authRequired: true },
    component: () => import("../views/transactions/journal-vouchers/index"),
  },
  {
    path: "/transactions/journal-vouchers/form",
    name: "AddJournalVoucher",
    meta: { title: "Add Journal Voucher", authRequired: true },
    component: () => import("../views/transactions/journal-vouchers/form"),
  },
  {
    path: "/transactions/journal-vouchers/form/:id",
    name: "EditJournalVoucher",
    meta: { title: "Edit Journal Voucher", authRequired: true },
    component: () => import("../views/transactions/journal-vouchers/form"),
  },
  {
    path: "/transactions/journal-vouchers/voucher/:id",
    name: "ViewJournalVoucher",
    meta: { title: "View Journal Voucher", authRequired: true },
    component: () =>
      import("../views/transactions/journal-vouchers/view-voucher"),
  },
  // Tax Management Input Vat
  {
    path: "/tax-management/input-vat-conversion",
    name: "TaxMngtInputVAT",
    meta: { title: "Input VAT - Conversion", authRequired: true },
    component: () =>
      import("../views/tax-management/input-vat-conversion/index"),
  },
  {
    path: "/tax-management/input-vat-conversion/form",
    name: "AddTaxMngtInputVAT",
    meta: { title: "Add Input VAT - COnversion", authRequired: true },
    component: () =>
      import("../views/tax-management/input-vat-conversion/form"),
  },
  {
    path: "/tax-management/input-vat-conversion/form/:id",
    name: "EditTaxMngtInputVAT",
    meta: { title: "Edit Input VAT - COnversion", authRequired: true },
    component: () =>
      import("../views/tax-management/input-vat-conversion/form"),
  },
  {
    path: "/tax-management/input-vat-conversion/voucher/:id",
    name: "ViewTaxMngtInputVAT",
    meta: { title: "View Input VAT - COnversion", authRequired: true },
    component: () =>
      import("../views/tax-management/input-vat-conversion/view-voucher"),
  },
  {
    path: "/tax-management/alpha-list",
    name: "TaxMngtAlphaList",
    meta: { title: "Alpha List", authRequired: true },
    component: () => import("../views/tax-management/alpha-list/index"),
  },

  // Tax Management Output Vat
  {
    path: "/tax-management/output-vat-conversion",
    name: "TaxMngtOutputVAT",
    meta: { title: "Output VAT - Conversion", authRequired: true },
    component: () =>
      import("../views/tax-management/output-vat-conversion/index"),
  },
  {
    path: "/tax-management/output-vat-conversion/form",
    name: "AddTaxMngtOutputVAT",
    meta: { title: "Add Output VAT - Conversion", authRequired: true },
    component: () =>
      import("../views/tax-management/output-vat-conversion/form"),
  },
  {
    path: "/tax-management/output-vat-conversion/form/:id",
    name: "EditTaxMngtOutputVAT",
    meta: { title: "Edit Output VAT - Conversion", authRequired: true },
    component: () =>
      import("../views/tax-management/output-vat-conversion/form"),
  },
  {
    path: "/tax-management/output-vat-conversion/voucher/:id",
    name: "ViewTaxMngtOutputVAT",
    meta: { title: "View Output VAT - Conversion", authRequired: true },
    component: () =>
      import("../views/tax-management/output-vat-conversion/view-voucher"),
  },

  // Tax Management Output Vat
  {
    path: "/tax-management/tax-settlement",
    name: "TaxSettlement",
    meta: { title: "Tax Settlement", authRequired: true },
    component: () => import("../views/tax-management/tax-settlement/index"),
  },

  // URL not yet exists
  {
    path: "/tax-management/tax-report",
    name: "TaxReport",
    meta: { title: "Tax Report", authRequired: true },
    component: () => import("../views/tax-management/tax-report/index"),
  },

  {
    path: "/government-compliance/reports/in-out-vat",
    name: "InputOutputVat",
    meta: { title: "Input / Output Vat", authRequired: true },
    component: () =>
      import("../views/tax-management/vat-report/input-ouput-vat"),
  },

  // VAT ZERO-RATED & Exempt Report
  {
    path: "/tax-management/vat-sales-report",
    name: "VatZeroRated&Exempt",
    meta: { title: "VAT ZERO-RATED & Exempt Report", authRequired: true },
    component: () =>
      import("../views/tax-management/vat-report/vat-zero-rate-exempt"),
  },
  // SAWT REPORT
  {
    path: "/tax-management/sawt-report",
    name: "SAWTReport",
    meta: { title: "SAWT Report", authRequired: true },
    component: () => import("../views/tax-management/vat-report/sawt"),
  },

  // CWT MONITORING
  {
    path: "/tax-management/cwt-monitoring",
    name: "CWTMonitoringReport",
    meta: { title: "CWT Monitoring Report", authRequired: true },
    component: () =>
      import("../views/tax-management/vat-report/cwt-monitoring"),
  },

  {
    path: "/ap-management/sundry-invoice",
    name: "SundryInvoice",
    meta: { title: "Sundry Invoice", authRequired: true },
    component: () => import("../views/ap-management/sundry-invoice/index"),
  },
  {
    path: "/ap-management/sundry-invoice/form",
    name: "CreateSundryInvoice",
    meta: { title: "Create Sundry Invoice", authRequired: true },
    component: () => import("../views/ap-management/sundry-invoice/form"),
  },
  {
    path: "/ap-management/sundry-invoice/form/:id",
    name: "EditSundryInvoice",
    meta: { title: "Edit Sundry Invoice", authRequired: true },
    component: () => import("../views/ap-management/sundry-invoice/form"),
  },
  {
    path: "/ap-management/sundry-payment",
    name: "SundryPayment",
    meta: { title: "Sundry Payment", authRequired: true },
    component: () => import("../views/ap-management/sundry-payment/index"),
  },
  {
    path: "/ap-management/sundry-payment/form",
    name: "CreateSundryPayment",
    meta: { title: "Create Sundry Payment", authRequired: true },
    component: () => import("../views/ap-management/sundry-payment/form"),
  },
  {
    path: "/ap-management/sundry-payment/form/:id",
    name: "EditSundryPayment",
    meta: { title: "Edit Sundry Payment", authRequired: true },
    component: () => import("../views/ap-management/sundry-payment/form"),
  },
  // Account Payable SOA Aging Report
  {
    path: "/reports/account-payable/soa/aging",
    name: "AcctPyblSOAAging",
    meta: { title: "AP - SOA", authRequired: true },
    component: () => import("../views/reports/account-payable/soa/aging"),
  },
  {
    path: "/reports/account-payable/soa/service-type",
    name: "AcctPyblSOAAgingByServiceType",
    meta: { title: "AP - SOA", authRequired: true },
    component: () =>
      import("../views/reports/account-payable/soa/service-type"),
  },
  {
    path: "/ap-management/prepayment",
    name: "Prepayment",
    meta: { title: "Prepayment", authRequired: true },
    component: () => import("../views/ap-management/prepayment/index"),
  },
  {
    path: "/ap-management/prepayment/form",
    name: "CreatePrepayment",
    meta: { title: "Create Prepayment", authRequired: true },
    component: () => import("../views/ap-management/prepayment/form"),
  },
  {
    path: "/ap-management/prepayment/form/:id",
    name: "EditPrepayment",
    meta: { title: "Edit Prepayment", authRequired: true },
    component: () => import("../views/ap-management/prepayment/form"),
  },
  {
    path: "/ap-management/prepayment/view",
    name: "ViewPrepayment",
    meta: { title: "View Prepayment", authRequired: true },
    component: () => import("../views/ap-management/prepayment/view"),
  },
  {
    path: "/ap-management/payment-releasing",
    name: "PaymentReleasing",
    meta: { title: "Payment Releasing", authRequired: true },
    component: () => import("../views/ap-management/payment-releasing/index"),
  },
  {
    path: "/ap-management/payment-releasing/view",
    name: "ViewPaymentReleasing",
    meta: { title: "Payment Releasing", authRequired: true },
    component: () => import("../views/ap-management/payment-releasing/view"),
  },

  // AR Management
  {
    path: "/ar-management/reports/payment-history",
    name: "PaymentHistory",
    meta: { title: "Payment History", authRequired: true },
    component: () =>
      import("../views/ar-management/reports/payment-history/index"),
  },

  {
    path: "/ar-management/advances",
    name: "Advances",
    meta: { title: "Advances", authRequired: true },
    component: () => import("../views/ar-management/advances/index"),
  },

  // Cash Management
  {
    path: "/cash-management/dashboard",
    name: "CashManagementDashboard",
    meta: { title: "Cash Management Dashboard", authRequired: true },
    component: () => import("../views/cash-management/dashboard/index"),
  },
  {
    path: "/cash-management/collection",
    name: "CollectionReport",
    meta: { title: "Collection", authRequired: true },
    component: () => import("../views/cash-management/collection/index"),
  },
  {
    path: "/cash-management/daily-collection",
    name: "DailyCollectionReport",
    meta: { title: "Daily Collection", authRequired: true },
    component: () => import("../views/cash-management/daily-collection/index"),
  },
  {
    path: "/cash-management/per-bank-activity",
    name: "PerBankActivityReport",
    meta: { title: "Per Bank Activity", authRequired: true },
    component: () => import("../views/cash-management/per-bank-activity/index"),
  },
  {
    path: "/cash-management/daily-bank-transactions",
    name: "DailyBankTransactions",
    meta: { title: "Daily Bank Transactions", authRequired: true },
    component: () =>
      import("../views/cash-management/daily-bank-transactions/index"),
  },
  {
    path: "/cash-management/disbursement",
    name: "DisbursementReport",
    meta: { title: "Disbursement Report", authRequired: true },
    component: () => import("../views/cash-management/disbursement/index"),
  },
  {
    path: "/cash-management/overall",
    name: "OverallReport",
    meta: { title: "Overvall Report", authRequired: true },
    component: () => import("../views/cash-management/overall/index"),
  },
  {
    path: "/cash-management/bank-recon/form",
    name: "BankReconUpload",
    meta: { title: "Bank Recon Upload", authRequired: true },
    component: () => import("../views/cash-management/bank-recon/form"),
  },
  //Audit Logs
  {
    path: "/reports/audit-logs",
    name: "AuditLogs",
    meta: { title: "Audit Logs", authRequired: true },
    component: () => import("../views/reports/audit-logs/index"),
  },
  {
    path: "/maintenance/form-types",
    name: "FormTypes",
    meta: { title: "Form Types", authRequired: true },
    component: () => import("../views/maintenance/printable-forms/types"),
  },
  {
    path: "/maintenance/form-items",
    name: "FormItems",
    meta: { title: "Form Items", authRequired: true },
    component: () => import("../views/maintenance/printable-forms/form_items"),
  },
  {
    path: "/maintenance/form-templates",
    name: "FormTemplates",
    meta: { title: "Form Templates", authRequired: true },
    component: () =>
      import("../views/maintenance/printable-forms/templates/index"),
  },
  {
    path: "/maintenance/form-template-builder",
    name: "CreateFormTemplate",
    meta: { title: "Create Form Template", authRequired: true },
    component: () =>
      import("../views/maintenance/printable-forms/templates/form"),
  },
  {
    path: "/maintenance/form-template-builder/:id",
    name: "EditFormTemplate",
    meta: { title: "Edit Form Template", authRequired: true },
    component: () =>
      import("../views/maintenance/printable-forms/templates/form"),
  },
  // Production Reports
  {
    path: "/production-reports/overall",
    name: "ProductionReportOverall",
    meta: { title: "Production Report Overall", authRequired: true },
    component: () => import("../views/pages/production-reports/overall/index"),
  },
  {
    path: "/production-reports/config-reports",
    name: "ConfigReports",
    meta: { title: "Config Reports", authRequired: true },
    component: () =>
      import("../views/pages/production-reports/config-reports/index"),
  },
  {
    path: "/production-reports/per-services",
    name: "PerServices",
    meta: { title: "Per Services", authRequired: true },
    component: () =>
      import("../views/pages/production-reports/per-services/index"),
  },
  {
    path: "/production-reports/per-services-details",
    name: "Per Services Details",
    meta: { title: "Per Services Details", authRequired: true },
    component: () =>
      import("../views/pages/production-reports/per-services-details/index"),
  },
  {
    path: "/production-reports/account-inquiries",
    name: "AccountInquiries",
    meta: { title: "Account Inquiries", authRequired: true },
    component: () =>
      import("../views/pages/production-reports/account-inquiries/index"),
  },

  // Executive Reports
  {
    path: "/executive-reports/overall",
    name: "ExecutiveReportOverall",
    meta: { title: "Executive Report Overall", authRequired: true },
    component: () => import("../views/pages/executive-reports/overall/index"),
  },
  // Audit and Validation
  {
    path: "/audit-validation/document-audit-trail",
    name: "DocumentAuditTrail",
    meta: { title: "Document Audit Trail", authRequired: true },
    component: () =>
      import("../views/audit-validation/document-audit-trail/index"),
  },
  // Billing
  {
    path: "/transactions/billing",
    name: "BillingList",
    meta: { title: "Billing", authRequired: true },
    component: () => import("../views/billing/index.vue"),
  },
  {
    path: "/transactions/billing/form/:id",
    name: "EditBilling",
    meta: { title: "Edit Billing", authRequired: true },
    component: () => import("../views/billing/form"),
  },
  {
    path: "/transactions/billing/form",
    name: "AddBilling",
    meta: { title: "Add Billing", authRequired: true },
    component: () => import("../views/billing/form.vue"),
  },

  // Services
  {
    path: "/maintenance/services",
    name: "Services",
    meta: { title: "Services", authRequired: true },
    component: () => import("../views/maintenance/services/index"),
  },

  // Charges
  {
    path: "/maintenance/charges",
    name: "Charges",
    meta: { title: "Charges", authRequired: true },
    component: () => import("../views/maintenance/charges/index"),
  },

  // Charges Groups
  {
    path: "/maintenance/charges-groups",
    name: "ChargesGroups",
    meta: { title: "Charges Groups", authRequired: true },
    component: () => import("../views/maintenance/charges-groups/index"),
  },
  // CI Series
  {
    path: "/maintenance/ci-series/index",
    name: "CreateCISeries",
    meta: { title: "Create CI Series", authRequired: true },
    component: () => import("../views/maintenance/ci-series/index"),
  },
  // Client Management Accreditataion Template
  {
    path: "/maintenance/accreditation-template",
    name: "AccreditationTemplate",
    meta: { title: "Accreditation Template", authRequired: true },
    component: () =>
      import("../views/maintenance/accreditation-template/index"),
  },
  // Client Management Accreditataion External Form
  {
    path: "/client-accreditation-form/ext/:token",
    name: "ExtAccreditationTemplate",
    meta: { title: "External Accreditation Template", authRequired: false },
    component: () => import("../views/maintenance/clients/ext_form"),
  },
  // Budget Management
  {
    path: "/budget/budget-management",
    name: "BudgetMgmt",
    meta: { title: "Budget Management", authRequired: true },
    component: () => import("../views/budget/budget-management"),
  },
  {
    path: "/transactions/prepayment-voucher",
    name: "PrepaymentVoucherList",
    meta: { title: "Prepayment Voucher", authRequired: true },
    component: () => import("../views/transactions/prepayment-voucher/index"),
  },
  {
    path: "/transactions/prepayment-voucher/form",
    name: "PrepaymentVoucherForm",
    meta: { title: "Prepayment Voucher Entry", authRequired: true },
    component: () => import("../views/transactions/prepayment-voucher/form"),
  },
  {
    path: "/transactions/prepayment-voucher/form/:id",
    name: "EditPrepaymentVoucherForm",
    meta: { title: "Prepayment Voucher Entry", authRequired: true },
    component: () => import("../views/transactions/prepayment-voucher/form"),
  },
  {
    path: "/transactions/recurring-invoice-template",
    name: "RecurringExpense",
    meta: { title: "Recurring Expense", authRequired: true },
    component: () =>
      import("../views/transactions/recurring-invoice-template/index"),
  },
  {
    path: "/transactions/recurring-invoice-template/form",
    name: "RecurringExpenseForm",
    meta: { title: "Recurring Expense Entry", authRequired: true },
    component: () =>
      import("../views/transactions/recurring-invoice-template/form"),
  },
  {
    path: "/transactions/recurring-invoice-template/form/:id",
    name: "EditRecurringExpenseForm",
    meta: { title: "Edit Recurring Expense Entry", authRequired: true },
    component: () =>
      import("../views/transactions/recurring-invoice-template/form"),
  },
  {
    path: "/maintenance/check-series/index",
    name: "CreateCheckSeries",
    meta: { title: "Create Check Series", authRequired: true },
    component: () => import("../views/maintenance/check-series/index"),
  },

  // Inventory Management
  {
    path: "/inventory-management/project-costing",
    name: "ProjectCosting",
    meta: { title: "Project Costing", authRequired: true },
    component: () =>
      import(
        "../views/inventory-management/project-costing/create-project/index"
      ),
  },
  {
    path: "/inventory-management/dashboard",
    name: "InventoryDashboard",
    meta: { title: "Inventory Dashboard", authRequired: true },
    component: () => import("../views/inventory-management/dashboard/index"),
  },
  {
    path: "/inventory-management/project-costing/categories",
    name: "Categories",
    meta: { title: "Categories", authRequired: true },
    component: () =>
      import("../views/inventory-management/project-costing/categories/index"),
  },
  {
    path: "/inventory-management/location",
    name: "Location",
    meta: { title: "Location", authRequired: true },
    component: () => import("../views/inventory-management/location/index"),
  },
  {
    path: "/inventory-management/location-category",
    name: "Location Category",
    meta: { title: "Location Category", authRequired: true },
    component: () =>
      import("../views/inventory-management/location/category/index"),
  },

  {
    path: "/inventory-management/maintenance/warehouses",
    name: "Warehouses",
    meta: { title: "Warehouses", authRequired: true },
    component: () =>
      import("../views/inventory-management/maintenance/warehouses/index"),
  },

  {
    path: "/inventory-management/withdrawal",
    name: "Withdrawal",
    meta: { title: "Withdrawal", authRequired: true },
    component: () => import("../views/inventory-management/withdrawal"),
  },

  {
    path: "/inventory-management/withdrawal/form",
    name: "WithdrawalForm",
    meta: { title: "Withdrawal Form", authRequired: true },
    component: () => import("../views/inventory-management/withdrawal/form"),
  },

  {
    path: "/inventory-management/withdrawal/view/:id",
    name: "ViewWithdrawal",
    meta: { title: "View Withdrawal", authRequired: true },
    component: () => import("../views/inventory-management/withdrawal/form"),
  },

  {
    path: "/inventory-management/return",
    name: "ReturnStock",
    meta: { title: "Return Stock", authRequired: true },
    component: () => import("../views/inventory-management/return"),
  },

  {
    path: "/inventory-management/return/form",
    name: "ReturnStockForm",
    meta: { title: "Return Stock Form", authRequired: true },
    component: () => import("../views/inventory-management/return/form"),
  },

  {
    path: "/inventory-management/return/view/:id",
    name: "ViewReturnStockForm",
    meta: { title: "View Return Stock Form", authRequired: true },
    component: () => import("../views/inventory-management/return/form"),
  },

  {
    path: "/inventory-management/report/current-stocks",
    name: "ReportCurrentStocks",
    meta: { title: "Inventory Current Stocks", authRequired: true },
    component: () =>
      import("../views/inventory-management/reports/current-stocks"),
  },
  {
    path: "/transactions/expense",
    name: "APExpense",
    meta: { title: "AP Expense", authRequired: true },
    component: () => import("../views/transactions/expense/index"),
  },
  {
    path: "/transactions/expense/form",
    name: "AddExpense",
    meta: { title: "Add AP Expense", authRequired: true },
    component: () => import("../views/transactions/expense/form"),
  },
  //fn transmittal
  {
    path: "/transactions/expense/fn",
    name: "FnTransmittals",
    meta: { title: "FN Transmittals", authRequired: true },
    component: () => import("../views/transactions/expense/fn/index"),
  },
  {
    path: "/transactions/expense/fn/form",
    name: "AddFNTransmittal",
    meta: { title: "Add FN Transmittal", authRequired: true },
    component: () => import("../views/transactions/expense/fn/form"),
  },
  {
    path: "/transactions/expense/fn/form/:id",
    name: "EditFNTransmittal",
    meta: { title: "Edit FN Transmittal", authRequired: true },
    component: () => import("../views/transactions/expense/fn/form"),
  },
  {
    path: "/transactions/expense/fn/view",
    name: "ViewFnTrasmittal",
    meta: { title: "View FN Transmittal", authRequired: true },
    component: () => import("../views/transactions/expense/fn/view"),
  },
  //fn transmittal

  //sundry transmittal
  {
    path: "/transactions/expense/sundry",
    name: "Sundry",
    meta: { title: "Sundry", authRequired: true },
    component: () => import("../views/transactions/expense/sundry/index"),
  },
  {
    path: "/transactions/expense/sundry/form",
    name: "AddSundry",
    meta: { title: "Add Sundry", authRequired: true },
    component: () => import("../views/transactions/expense/sundry/form"),
  },
  {
    path: "/transactions/expense/sundry/form/:id",
    name: "EditSundry",
    meta: { title: "Edit Sundry", authRequired: true },
    component: () => import("../views/transactions/expense/sundry/form"),
  },
  {
    path: "/transactions/expense/sundry/view",
    name: "ViewSundry",
    meta: { title: "View Sundry", authRequired: true },
    component: () => import("../views/transactions/expense/sundry/view"),
  },
  //sundry transmittal

  //direct transmittal
  {
    path: "/transactions/expense/direct",
    name: "DirectExpenses",
    meta: { title: "Direct Expense", authRequired: true },
    component: () => import("../views/transactions/expense/direct/index"),
  },
  {
    path: "/transactions/expense/direct/form",
    name: "AddDirectExpense",
    meta: { title: "Add Direct Expense", authRequired: true },
    component: () => import("../views/transactions/expense/direct/form"),
  },
  {
    path: "/transactions/expense/direct/form/:id",
    name: "EditDirectExpense",
    meta: { title: "Edit Direct Expense", authRequired: true },
    component: () => import("../views/transactions/expense/direct/form"),
  },
  {
    path: "/transactions/expense/direct/view",
    name: "ViewDirectExpense",
    meta: { title: "View Direct Expense", authRequired: true },
    component: () => import("../views/transactions/expense/direct/view"),
  },
  //direct transmittal


  //start of AP Trade
  {
    path: "/transactions/expense/ap-trade",
    name: "APTrade",
    meta: { title: "AP Trade Transmittals", authRequired: true },
    component: () => import("../views/transactions/expense/trade/index"),
  },
  {
    path: "/transactions/expense/ap-trade/form",
    name: "AddAPTrade",
    meta: { title: "Add AP Trade", authRequired: true },
    component: () => import("../views/transactions/expense/trade/form"),
  },
  {
    path: "/transactions/expense/ap-trade/form/:id",
    name: "EditAPTrade",
    meta: { title: "Edit AP Trade", authRequired: true },
    component: () => import("../views/transactions/expense/trade/form"),
  },
  {
    path: "/transactions/expense/ap-trade/view",
    name: "ViewAPTrade",
    meta: { title: "View AP Trade", authRequired: true },
    component: () => import("../views/transactions/expense/trade/view"),
  },

  //end of AP Trade


  {
    path: "/transactions/expense/form/:id",
    name: "EditExpense",
    meta: { title: "Edit AP Expense", authRequired: true },
    component: () => import("../views/transactions/expense/form"),
  },
  {
    path: "/transactions/expense/view",
    name: "ViewExpense",
    meta: { title: "View AP Invoice", authRequired: true },
    component: () => import("../views/transactions/expense/view"),
  },

  // APExpenseSOBIPrepmt
  // SOBI
  {
    path: "/transactions/expense/SOBI",
    name: "APExpenseSOBI",
    meta: { title: "AP Expense", authRequired: true },
    component: () => import("../views/transactions/expense/sobi/sobi-index"),
  },
  {
    path: "/transactions/expense/SOBI/form",
    name: "AddExpenseSOBI",
    meta: { title: "Add AP Expense", authRequired: true },
    component: () => import("../views/transactions/expense/sobi/sobi-form"),
  },
  {
    path: "/transactions/expense/SOBI/form/:id",
    name: "EditExpenseSOBI",
    meta: { title: "Edit AP Expense", authRequired: true },
    component: () => import("../views/transactions/expense/sobi/sobi-form"),
  },
  {
    path: "/transactions/expense/SOBI/view",
    name: "ViewExpenseSOBI",
    meta: { title: "View AP Invoice", authRequired: true },
    component: () => import("../views/transactions/expense/sobi/sobi-view"),
  },

  
  // Prepayment
  {
    path: "/transactions/expense/Prepayment",
    name: "APExpensePrepayment",
    meta: { title: "AP Expense", authRequired: true },
    component: () => import("../views/transactions/expense/prepayment/prepmt-index"),
  },
  {
    path: "/transactions/expense/Prepayment/form",
    name: "AddExpensePrepayment",
    meta: { title: "Add AP Expense", authRequired: true },
    component: () => import("../views/transactions/expense/prepayment/prepmt-form"),
  },
  {
    path: "/transactions/expense/Prepayment/form/:id",
    name: "EditExpensePrepayment",
    meta: { title: "Edit AP Expense", authRequired: true },
    component: () => import("../views/transactions/expense/prepayment/prepmt-form"),
  },
  {
    path: "/transactions/expense/Prepayment/view",
    name: "ViewExpensePrepayment",
    meta: { title: "View AP Invoice", authRequired: true },
    component: () => import("../views/transactions/expense/prepayment/prepmt-view"),
  },
  
  //Recurring voucher tempalte
  {
    path: "/transactions/recurring-voucher-template/form",
    name: "CreateRecurringVoucherTemplate",
    meta: { title: "Create Recurring Voucher Template", authRequired: true },
    component: () =>
      import("../views/transactions/recurring-voucher-template/form"),
  },
  {
    path: "/transactions/recurring-voucher-template/jv",
    name: "RecurringVoucherTemplateJv",
    meta: { title: "Recurring Journal Voucher", authRequired: true },
    component: () =>
      import("../views/transactions/recurring-voucher-template/jv"),
  },
  {
    path: "/transactions/recurring-voucher-template/jv_report",
    name: "RecurringVoucherTemplateJvReport",
    meta: { title: "Recurring Journal Voucher", authRequired: true },
    component: () =>
      import("../views/transactions/recurring-voucher-template/jv_report"),
  },

  {
    path: "/transactions/recurring-voucher-template",
    name: "RecurringVoucherTemplates",
    meta: { title: "Process Journal Voucher", authRequired: true },
    component: () =>
      import("../views/transactions/recurring-voucher-template/index"),
  },
  {
    path: "/transactions/recurring-voucher-template/:id",
    name: "EditRecurringVoucherTemplates",
    meta: { title: "Recurring Voucher Template", authRequired: true },
    component: () =>
      import("../views/transactions/recurring-voucher-template/form"),
  },

  // Advances Report
  {
    path: "/reports/advances",
    name: "AdvancesReport",
    meta: { title: "Advances Report", authRequired: true },
    component: () => import("../views/reports/advances"),
  },

  // Expenses Report
  {
    path: "/reports/expenses",
    name: "ExpensesReport",
    meta: { title: "Expenses Report", authRequired: true },
    component: () => import("../views/reports/expenses"),
  },
];